jQuery(document).ready(function ($) {


    /**
     * Одиночный слайдер
     */
    $('#single-slider').slick({
        dots: true,
        arrows: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: $('#single-slider-wrap .slick-prev'),
        nextArrow: $('#single-slider-wrap .slick-next'),
    });

    /**
     * Слайдер фото/видео
     * @type {*|jQuery|HTMLElement}
     */
    let sliderItems = $('.slider-items');
    sliderItems.each(function () {
        let $this = $(this);
        $this.slick({
            dots: false,
            arrows: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            prevArrow: $this.closest('.wrap').find('.slick-prev'),
            nextArrow: $this.closest('.wrap').find('.slick-next'),
            infinite: false,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    }
                },
            ]
        })
    });



    /**
     * Меню для мобильного
     */
    $('.btn-menu').on('click', function (e) {
        e.preventDefault();
        $(this).toggleClass('active');
        $('#nav').slideToggle();
    })

    /**
     * Фиксированная Кнопка вверх
     */
    let btnUp = $('.btn-up');
    $(window).on('scroll', function (e) {
        if ($(this).scrollTop() > 300) {
            btnUp.fadeIn();
        } else {
            btnUp.fadeOut();
        }
    });

    btnUp.on('click', function (e) {
        e.preventDefault();
        $('body, html').animate({
            scrollTop: 0
        }, 500);
    });


    /**
     * Галерея
     */
    $('.media li a').on('click', function (e) {
        e.preventDefault();
        $(this).parent().siblings().removeClass('active');
        $(this).parent().addClass('active');
        let imgSrc = $(this).attr('href');
        $('.media figure img').fadeOut(1, function () {
            $(this).attr('src', imgSrc).fadeIn(1);
        });
    });

    /**
     * Кол-во
     */
    $('.count a').on('click', function (e) {
        e.preventDefault();
        let inputCount = $(this).parents('.count').find('input');
        let count = parseInt(inputCount.val());
        if ($(this).hasClass('plus')) {
            count++;

        } else if ($(this).hasClass('minus') && count > 1) {
            count--;
        }
        inputCount.val(count);
    });

    /**
     * Шаги
     */
    $(document).on('click', '.btn-next, .link-back', function (e) {
        e.preventDefault();
        let $this = $(this),
            $step = $('.step'),
            $index = $this.closest('.step').index(),
            $prevStep = $step.eq($index);

        if ($this.hasClass('link-back')) {
            $index -= 1;
        } else {
            $index += 1;
        }

        let $curStep = $step.eq($index);

        $prevStep.removeClass('active');
        $curStep.addClass('active');

        if (window.screen.width < 1200) {
            let $steps = $('#steps');

            $('body, html').animate({
                scrollTop: $steps.offset().top - 50
            }, 300);
        }
    });


    /**
     * Search
     */
    $('.btn-search').on('click', function (e) {
        e.preventDefault();
        $('.quick-search').toggleClass('active').find('input').focus();
    });


});


